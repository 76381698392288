import { ArdoqId, AssetType } from '@ardoq/api-types';
import {
  CreateReportFromLocation,
  trackCreateReport,
} from '@ardoq/report-reader';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  openHomeWithFilter,
  openProductboardPortal,
  requestShowAppModule,
} from 'appContainer/actions';
import { maybeShowConfirmCloseScenario } from 'appContainer/maybeShowConfirmCloseScenario';
import { AppModules } from 'appContainer/types';
import { openAssetsBrowser } from 'components/assetsBrowserDialog/actions';
import { openConsentManagement } from 'consentManagement/utils';
import Context from 'context';
import { copyWsToOrgModal } from 'copyWsToOrg/copyWsToOrgModal';
import { DashboardModule } from 'dashboard/types';
import { setVisibleIntegration } from 'integrations/actions';
import { selectMetamodelPane } from 'metamodel/navigation/actions';
import { MetamodelPane } from 'metamodel/navigation/types';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import {
  navigateToAnalyticsOverview,
  navigateToDashboardModule,
  navigateToOrganizationMetamodel,
  navigateToReportBuilder,
  NavigateToReportBuilderPayload,
  NavigateToReportModulePayload,
  navigateToReportOverview,
  navigateToReportReader,
  navigateToSubdivision,
} from 'router/navigationActions';
import { closeScenario } from 'scope/actions';
import { openDynamicSearchModal } from 'search/DynamicSearch/actions';
import {
  SubdivisionEditorSteps,
  SubdivisionEditorSubStep,
} from 'subdivisionEditor/navigation/types';
import * as surveyUtils from 'surveyAdmin/surveyUtil';
import { showSupport } from 'utils/support';
import {
  selectSearchPane,
  setQueryEditorIsOpenInModal,
} from '../../search/SearchTabContainer/actions';
import { SearchPane } from '../../search/SearchTabContainer/types';
import { trackEvent } from '../../tracking/tracking';
import cantManageUsers from './CantManageUsers';
import editTemplatesModal from './editTemplates/editTemplates';

export const openHome = () => {
  dispatchAction(requestShowAppModule({ selectedModule: AppModules.HOME }));
};

export const openAssetsBrowserDialog = async () => {
  if (isScenarioMode()) {
    const didConfirm = await maybeShowConfirmCloseScenario();
    if (!didConfirm) return;
    dispatchAction(closeScenario());
  }
  dispatchAction(openAssetsBrowser());
};

export const openIntegrationPage = () => {
  dispatchAction(setVisibleIntegration({ id: null }));
};

/** LEGACY CODE */
export const editTemplates = async () => {
  await editTemplatesModal();
};

export const openCopyWorkspacesModal = () => {
  copyWsToOrgModal();
};

const openAnalyticsModule = () => {
  dispatchAction(requestShowAppModule({ selectedModule: AppModules.SEARCH }));
};

export const openAdvancedSearch = () => {
  openAnalyticsModule();
  dispatchAction(selectSearchPane({ searchPane: SearchPane.ADVANCED_SEARCH }));
};

export const openGremlinSearch = () => {
  openAnalyticsModule();
  dispatchAction(selectSearchPane({ searchPane: SearchPane.GREMLIN }));
};

export const openDashboard = ({
  dashboardId,
  dashboardModule = DashboardModule.READER,
}: {
  dashboardId?: ArdoqId;
  dashboardModule?: DashboardModule;
}) =>
  dispatchAction(
    navigateToDashboardModule({
      dashboardModule,
      ...(dashboardId ? { selectedDashboardId: dashboardId } : {}),
    })
  );

export const createFromSelectedComponent =
  surveyUtils.createFromSelectedComponent;

export const openPresentations = () => {
  trackEvent('Sidebar: open presentations');
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.PRESENTATIONS })
  );
};

export const openChatWithUs = () => showSupport({ chat: true });

export const openHelp = () => {
  const newTab = window.open('https://help.ardoq.com/', '_blank');
  newTab?.focus();
};

export const openUserForum = () => {
  const newTab = window.open('https://community.ardoq.com/', '_blank');
  newTab?.focus();
};

export const openArdoqApi = () => {
  dispatchAction(setVisibleIntegration({ id: 'public-api' }));
};

export const reportBug = () => {
  showSupport({
    chat: false,
  });
};

export const openEmailPreferencies = () => {
  openConsentManagement();
};

export const logout = () => {
  if (window?.Intercom) {
    window.Intercom('shutdown');
  }
  trackEvent('Topbar: clicked log out');
  window.location.href = '/logout';
};

export const openManageUsers = async () => {
  cantManageUsers();
};

export const openGraphFilters = () => {
  dispatchAction(setQueryEditorIsOpenInModal(true));
  dispatchAction(openDynamicSearchModal());
};

export const openWorkspace = () => {
  if (Context.activeWorkspaceId()) {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
    );
  }
};

export const openActiveMetamodel = () => {
  dispatchAction(selectMetamodelPane({ pane: MetamodelPane.SELECTED }));
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.METAMODEL })
  );
};

export const openMetamodelOverview = () => {
  dispatchAction(selectMetamodelPane({ pane: MetamodelPane.LIST }));
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.METAMODEL })
  );
};

export const openOrganizationMetamodelOverview = () => {
  dispatchAction(
    navigateToOrganizationMetamodel({
      route: '/organization-metamodel/workspaces',
      params: {},
    })
  );
};

export const openOrganizationMetamodelTriples = () => {
  dispatchAction(
    navigateToOrganizationMetamodel({
      route: '/organization-metamodel/triples',
      params: {},
    })
  );
};

export const openProductPortal = () => {
  dispatchAction(openProductboardPortal());
};

export const openCreateReport = (
  from: CreateReportFromLocation,
  navigateToReportBuilderPayload?: NavigateToReportBuilderPayload
) => {
  trackCreateReport(trackEvent, from);
  dispatchAction(navigateToReportBuilder(navigateToReportBuilderPayload ?? {}));
};

export const openReportInBuilder = (
  navigationPayload: Omit<NavigateToReportModulePayload, 'filters'>
) => dispatchAction(navigateToReportBuilder(navigationPayload));

export const openReportInReader = (
  navigationPayload: NavigateToReportModulePayload
) => dispatchAction(navigateToReportReader(navigationPayload));

export const openReportOverview = () =>
  dispatchAction(navigateToReportOverview());

export const openSubdivisionEditor = (
  subdivisionId: ArdoqId,
  subdivisionEditorStep: SubdivisionEditorSteps = SubdivisionEditorSteps.DETAILS,
  subdivisionEditorSubStep?: SubdivisionEditorSubStep
) =>
  dispatchAction(
    navigateToSubdivision({
      subdivisionId,
      subdivisionEditorStep,
      subdivisionEditorSubStep,
    })
  );

export const openCreateSubdivision = () =>
  dispatchAction(
    navigateToSubdivision({
      subdivisionId: 'new',
      subdivisionEditorStep: SubdivisionEditorSteps.DETAILS,
    })
  );

export const goToReportAndDashboardOverview = (
  hasNewJourneyFeature: boolean
) =>
  hasNewJourneyFeature
    ? dispatchAction(navigateToAnalyticsOverview())
    : dispatchAction(
        openHomeWithFilter([AssetType.REPORT, AssetType.DASHBOARD])
      );
