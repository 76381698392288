import { Fragment, useRef } from 'react';
import { TextInput } from '@ardoq/forms';
import { Stack, FlexBox, Box } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import { Select } from '@ardoq/select';
import { VisualComponentsMappingsProps } from './types';
import { ButtonSize, IconButton, SecondaryButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { colors } from '@ardoq/design-tokens';

export const VisualComponentsMappings = ({
  mappingsStatus,
  components,
  componentTypeOptions,
  onComponentMapping,
  onComponentTypeChange,
  onAddNewComponentMapping,
  onDeleteComponentMapping,
}: VisualComponentsMappingsProps) => {
  const focusedInputRef = useRef<string | null>(null);

  const isSuccess = mappingsStatus === ASYNC_STATUS.SUCCESS;

  return (
    <Stack gap="large">
      <Box paddingLeft="medium" paddingTop="medium">
        <Text variant="text2">
          Verify the component name and the component type from the diagram. Add
          new component if missing.
        </Text>
      </Box>
      <FlexBox justify="end" paddingLeft="medium">
        <SecondaryButton
          onClick={onAddNewComponentMapping}
          isDisabled={isSuccess}
        >
          <Icon iconName={IconName.ADD} />
          Add new component
        </SecondaryButton>
      </FlexBox>
      {components.map(component => {
        // TODO: move the data prep to the viewModel
        const componentTypes = (
          component.componentTypeId
            ? componentTypeOptions
            : [
                {
                  value: component.componentTypeId ?? component.type,
                  label: component.type,
                  isDisabled: true,
                },
                ...componentTypeOptions,
              ]
        ).filter(({ label }) => Boolean(label));

        return (
          <Fragment key={`component-mapping-${component.id}`}>
            <FlexBox gap="none" key={component.name} align="start">
              <FlexBox flex={1} paddingX="medium" paddingY="xsmall">
                <Box width="full">
                  <TextInput
                    key={`component-input-${component.id}`}
                    onValueChange={name => {
                      onComponentMapping({
                        ...component,
                        name,
                      });
                    }}
                    onFocus={() => {
                      focusedInputRef.current = component.id;
                    }}
                    onBlur={() => {
                      focusedInputRef.current = null;
                    }}
                    autoFocus={focusedInputRef.current === component.id}
                    defaultValue={component.name}
                    warningMessage={
                      !component.name.trim()
                        ? 'Missing component name'
                        : undefined
                    }
                  />
                </Box>
              </FlexBox>
              <FlexBox
                flex={1}
                paddingLeft="medium"
                paddingY="xsmall"
                justify="center"
              >
                <Box width="full">
                  <Select
                    key={`component-type-select-${component.id}`}
                    onValueChange={componentTypeId => {
                      if (componentTypeId !== null) {
                        onComponentTypeChange(component, componentTypeId);
                      }
                    }}
                    warningMessage={
                      !component.componentTypeId
                        ? 'Missing component type'
                        : undefined
                    }
                    value={component.componentTypeId ?? component.type}
                    options={componentTypes}
                  />
                </Box>
                <Box>
                  {isSuccess ? (
                    <Box paddingLeft="xsmall" paddingTop="xsmall">
                      <Icon
                        iconName={IconName.CHECK_CIRCLE}
                        color={colors.surfaceSuccessStrong}
                      />
                    </Box>
                  ) : (
                    <IconButton
                      iconName={IconName.DELETE}
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => onDeleteComponentMapping(component)}
                    />
                  )}
                </Box>
              </FlexBox>
            </FlexBox>
          </Fragment>
        );
      })}
    </Stack>
  );
};
