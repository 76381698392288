import {
  ApiCountInstancesRequest,
  APICountInstancesResponse,
  APIViewpointAttributes,
  BooleanOperator,
  ComponentSelection,
  DirectedTriple,
  DirectedTripleWithFilters,
  FieldGlobalAttributes,
  MetaModelReferenceType,
  Operator,
  PathCollapsingRule,
  QueryBuilderQuery,
  QueryBuilderRuleValue,
  TraversalPathMatchingType,
  TripleDirection,
  ViewpointBuilderFilters,
  APIFieldAttributes,
} from '@ardoq/api-types';
import { GraphModelShape, LinkedComponent } from '@ardoq/data-model';
import { EnhancedScopeDataWithBranchName } from '@ardoq/renderers';
import {
  CollapsibleGraphGroup,
  ComponentRepresentationData,
  GraphEdge,
  GraphItemId,
  GraphNode,
  ReferenceTypeRepresentationData,
  GraphEdgeWithMetaData,
  GraphNodeWithMetaData,
} from '@ardoq/graph';
import type { BlockDiagramViewSettings } from 'tabview/blockDiagram/types';
import { GraphInterface } from './components/SimpleGraph/GraphInterface';
import { IconName } from '@ardoq/icons';
import {
  ViewpointBuilderNavigationViewModel,
  ViewpointBuilderTab,
} from './viewpointBuilderNavigation/types';
import {
  ComponentSearchData,
  ComponentSearchViewState,
} from 'viewpointBuilder/selectContextTab/types';
import { MetamodelViewCommands, MetamodelViewState } from './metaModel/types';
import { ComponentsSearchCommands } from 'viewpointBuilder/selectContextTab/commands';
import { ViewpointBuilderFiltersCommands } from './addFiltersTab/viewpointBuilderFiltersCommands';
import { ViewpointBuilderNavigationCommands } from './viewpointBuilderNavigation/viewpointBuilderNavigationCommands';
import { EditTraversalCommands } from './traversals/editTraversalCommands';
import {
  AsyncLabelLoaders,
  AsyncSuggestionsLoaders,
  QueryBuilderFilterDefinition,
} from '@ardoq/query-builder';
import { QueryBuilderFilterDefinitionGroups } from './addFiltersTab/composeViewpointBuilderComponentsFilterGroups';
import { MetaInfoState } from './viewpointMetaInfo/metaInfoTypes';
import { MetaInfoCommands } from './viewpointMetaInfo/metaInfoCommands';
import { ViewpointGroupingRulesCommands } from './addGroupingRulesTab/viewpointGroupingRulesCommands';
import { GroupingRulesState } from './addGroupingRulesTab/groupingRulesTypes';
import { ViewpointBuilderFormattingCommands } from './formattingTabs/viewpointBuilderFormattingCommands';
import { PrimaryButtonConfig } from './openViewpointBuilder/getPrimaryButtonConfig';
import {
  EditablePathCollapsingRule,
  PathCollapsingViewState,
} from './collapsePathsTab/types';
import { CollapsePathsCommands } from './collapsePathsTab/collapsePathsCommands';
import { ViewpointFormattingProps } from './formattingTabs/viewpointFormattingTypes';
import { RequiredComponentsCommands } from './requiredComponentsTab/requiredComponentsCommands';
import { RequiredComponentsViewState } from './requiredComponentsTab/types';
import { SelectedViewCommands } from './selectedView/commands';

/**
 * A component or reference id.
 * */
type EntityId = string;
/**
 * A id used in the selected graph. The selected graph can contain several
 * instances of a component or reference type.
 */

export type GraphId = string;

export type TripleMap = Map<
  string,
  { sourceId: EntityId; referenceId: EntityId; targetId: EntityId }
>;

export type MetadataComponent = {
  representationData: ComponentRepresentationData & {
    shadedColor: string;
    lightenedColor: string;
    contrastColor: string;
  };
  color: string;
  typeId: string;
  label: string;
};

export type MetaDataReference = {
  representationData: ReferenceTypeRepresentationData | null;
};

export type NamedDirectedTriple = {
  direction: TripleDirection;
  sourceType: string;
  referenceType: string;
  targetType: string;
};

export type TripleOption = {
  tripleId: string;
  isSelected: boolean;
  metaDataComponent: MetadataComponent;
  metaDataReference: MetaDataReference;
  namedDirectedTriple: NamedDirectedTriple;
  countKey: string;
  instanceCounts: number | null;
  isDisabled: boolean;
  togglingDisabledExplanation?: string;
};

export type TripleOptions = {
  incoming: TripleOption[];
  outgoing: TripleOption[];
  instanceCountsResponse: APICountInstancesResponse | null;
  selectedNode: {
    metaDataComponent: MetadataComponent;
  };
  selectedEdge?: {
    metaDataComponent: MetaDataReference;
  };
  fetchCountsArgs: ApiCountInstancesRequest | null;
  optionCountLoadingState: 'idle' | 'loading' | 'loaded' | 'error';
  showOnlyOptionsWithInstanceCounts: boolean; // TODO AM do we need this one?
};

export type SelectedGraphModelMap = Map<
  string,
  (LinkedComponent & { tripleId: string })[]
>;

export type SelectedGraph = {
  sourceMap: SelectedGraphModelMap;
  targetMap: SelectedGraphModelMap;
  referenceMap: Map<
    GraphId,
    {
      sourceId: GraphId;
      targetId: GraphId;
      tripleId: string;
    }
  >;
};

/**
 * This map holds the data needed to display the components nicely.
 */
export type RawGraphNodes = Map<
  EntityId,
  {
    modelId: EntityId;
    label: string;
    metaData: {
      representationData: ComponentRepresentationData & {
        shadedColor: string;
        lightenedColor: string;
        contrastColor: string;
      };
      color: string;
      typeId: string;
      label: string;
    };
  }
>;
/**
 * This map holds the data needed to display the references nicely.
 */
export type RawGraphEdges = Map<
  EntityId,
  {
    modelId: EntityId;
    sourceId: EntityId;
    targetId: EntityId;
    metaData: {
      representationData: ReferenceTypeRepresentationData;
    };
  }
>;

export type ErrorBuilderState = 'NONE';

export type ExpandableSectionId = 'outgoingReferences' | 'incomingReferences';

export type InstanceCounts = {
  namedDirectedTriplePath: DirectedTriple[];
  graphNodeId: GraphId;
  pathKey: string;
  label: string;
  counts?: number;
};

export type ViewpointBuilderFilter = {
  nonPersistentId: string;
  graphNodeId: GraphId;
  filterBy: string;
  operator: Operator;
  value: QueryBuilderRuleValue | undefined;
  filterDefinition: QueryBuilderFilterDefinition;
};

export type TraversalBuilderFiltersPerNode = {
  filters: ViewpointBuilderFilter[];
  condition: BooleanOperator;
  localFilterId: string;
};

export type ViewpointBuilderFiltersDefinitions = {
  componentFilterTypeOptions: {
    label: string;
    options: { label: string; value: string }[];
  }[];
  componentsFilterGroups: QueryBuilderFilterDefinitionGroups;
};

export type TraversalBuilderViewState = Omit<
  TraversalBuilderState,
  'triplesSortAndFiltersState'
> & {
  triplesSortAndFiltersState: TriplesSortAndFiltersViewState;
  filtersDefinitions: ViewpointBuilderFiltersDefinitions;
  asyncLoaders: {
    asyncSuggestionsLoaders: AsyncSuggestionsLoaders;
    asyncLabelLoaders: AsyncLabelLoaders;
  };
  stateAsSavableAttributes: TraversalBuilderAsSavableAttributes;
  perspectivesOptionsArgs: {
    workspaceIds: string[];
    componentTypeNames: string[];
    referenceTypeNames: string[];
    availableComponentFields: FieldGlobalAttributes[];
    availableReferenceFields: FieldGlobalAttributes[];
    componentFieldsByType: Map<string, APIFieldAttributes[]>;
    referenceFieldsByType: Map<string, APIFieldAttributes[]>;
  };
  pathCollapsingViewState: PathCollapsingViewState;
  shouldShowDeleteCurrentNodeButton: boolean;
  requiredComponentsState: RequiredComponentsViewState;
  /**
   * We hide the notification if there is only one node in the graph - the context component type
   * even if shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings is true.
   */
  shouldIncludeClickOtherComponentTypeNodesNotification: boolean;
};

export type TraversalBuilderAsSavableAttributes = {
  startSetFilterId: string | null;
  pathMatching: TraversalPathMatchingType;
  paths: DirectedTripleWithFilters[][];
  filters: ViewpointBuilderFilters;
  startType: string | null;
  pathCollapsingRules: PathCollapsingRule[];
};

export type PathCollapsingState = {
  rules: PathCollapsingRuleInternal[];
  currentEditableRule: EditablePathCollapsingRule | null;
};

export type TriplesSortAndFiltersState = {
  filterTerm: string;
  showOnlyOptionsWithInstanceCounts: boolean;
  tripleSortOrder: TripleSortOrder;
};

export type TriplesSortAndFiltersViewState = TriplesSortAndFiltersState & {
  outgoingTriplesHiddenCount: number;
  incomingTriplesHiddenCount: number;
};

export type TraversalBuilderState = {
  /**
   * Relations are expressed with entity ids.
   * This is the whole meta model graph.
   */
  baseGraph: Omit<GraphModelShape, 'referenceTypes'>;
  componentNameAndFieldsIndex: Map<string, string[]>;
  componentNameAndWorkspacesIndex: Map<string, string[]>;
  enhancedScopeData: EnhancedScopeDataWithBranchName | null;
  errorState: ErrorBuilderState;
  fieldMap: Map<string, APIFieldAttributes>;
  filteredTripleOptions: TripleOptions;
  filters: Record<GraphId, TraversalBuilderFiltersPerNode>;
  /**
   * Maps which hold the graph nodes instances to instantiate the according
   * yFiles graph entities.
   */
  graphEdges: Map<GraphId, GraphEdgeWithMetaData>;
  graphGroups: Map<GraphId, CollapsibleGraphGroup>;
  graphNodes: Map<GraphId, GraphNodeWithMetaData>;
  hasTripleOptions: Record<ExpandableSectionId, boolean>;
  highlightedPaths: Record<string, HighlightedPath[]>;
  initialFilters: ViewpointBuilderFilters | null;
  initialPathCollapsingRules: PathCollapsingRule[] | null;
  initialPaths: DisableableDirectedTripleWithFilters[][] | null;
  instanceCounts: Map<GraphId, InstanceCounts>;
  isMetaModelLoaded: boolean;
  pathCollapsing: PathCollapsingState;
  pathMatching: TraversalPathMatchingType;
  rawGraphEdges: RawGraphEdges;
  rawGraphNodes: RawGraphNodes;
  referenceNameAndFieldsIndex: Map<string, string[]>;
  referenceTypes: MetaModelReferenceType[];
  requiredNodeIds: string[];
  /**
   * Relations are expressed with graph ids.
   * This graph may have several
   * instances of a given entity type of the base graph.
   * The according entity id can be resolved with the graphNodes map
   * with `graphNodes.get(graphId).modelId`.
   */
  selectedGraph: SelectedGraph;
  selectedGraphEdgeId: string | null;
  selectedGraphNodeId: string | null;
  /**
   * Stored in user settings - the user can click the "Don't show again" button and set it permanently to false.
   * If user selects a component type, the hint will not be hidden as long as the ViewpointBuilderState is kept.
   */
  shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings: boolean;
  shouldIncludeInstanceCounts: boolean;
  startComponentType: string | null;
  startComponentTypeId: EntityId | null;
  startNode: GraphNodeWithMetaData | null;
  /**
   * Start query passed from componentSearch state stream with state routine.
   */
  traversalStartQuery: QueryBuilderQuery | null;
  /**
   * Selected components passed from componentSearch state stream with state routine.
   */
  traversalStartSet: string[] | null;
  tripleOptions: TripleOptions;
  triples: TripleMap;
  triplesSortAndFiltersState: TriplesSortAndFiltersState;
};

export type HighlightedPath = {
  referenceIds: string[];
  componentIds: string[];
  status: 'error' | 'default';
};

export type TraversalBuilderStateWithCurrentEditableRule =
  TraversalBuilderState & {
    pathCollapsing: {
      currentEditableRule: EditablePathCollapsingRule;
    };
  };

export const isTraversalBuilderStateWithCurrentEditableRule = (
  state: TraversalBuilderState
): state is TraversalBuilderStateWithCurrentEditableRule =>
  Boolean(state.pathCollapsing.currentEditableRule);

export type Direction = 'outgoing' | 'incoming';

export type SimpleViewModel = {
  graphNodes: Map<GraphId, GraphNode>;
  graphEdges: Map<GraphId, GraphEdge>;
  graphGroups: Map<GraphId, CollapsibleGraphGroup>;
};

export type SimpleGraphViewProps = {
  viewInstanceId?: string;
  viewSettings?: BlockDiagramViewSettings;
  clearEdges?: boolean;
  viewModel: SimpleViewModel;
  label?: string;
  graphInterface?: GraphInterface;
  topRightCornerButton?: {
    label: string;
    iconName?: IconName;
    onClick: () => void;
  } | null;
  hasZoomControls?: boolean;
  /**
   * If set to true the view will have no zoom controls, not be zoomable nor
   * pannable with the mouse.
   */
  isThumbnailView?: boolean;
  /**
   * When this flag is set to true, the graph is automatically re-scaled and
   * re-centered to fit within its container. By default, this flag is enabled.
   * Its primary function is to prevent unnecessary re-scaling and re-centering
   * of the graph when a user simply selects a different node.
   */
  shouldFitToContentOnLayout?: boolean;
  hasHoverDecorator?: boolean;
};

export type TripleSortOrder =
  | 'reference_type_a_z'
  | 'component_type_a_z'
  | 'descending_instance_count';

// TODO (chriskr): Remove this type
export type EditTraversalState = TraversalBuilderState;

export type ViewpointBuilderState = {
  viewpointBuilderNavigationState: ViewpointBuilderNavigationViewModel;
  searchComponentsState: ComponentSearchViewState;
  metamodelViewState: MetamodelViewState;
  editTraversalsState: TraversalBuilderViewState;
  metaInfoState: MetaInfoState;
  groupingsState: GroupingRulesState;
  formattingState: ViewpointFormattingProps;
};

export type ViewpointBuilderViewState = ViewpointBuilderState & {
  primaryButtonConfig: PrimaryButtonConfig;
};

export type ViewpointBuilderCommands = {
  componentsSearchCommands: ComponentsSearchCommands;
  metamodelViewCommands: MetamodelViewCommands;
  filterCommands: ViewpointBuilderFiltersCommands;
  navigationCommands: ViewpointBuilderNavigationCommands;
  editTraversalCommands: EditTraversalCommands;
  metaInfoCommands: MetaInfoCommands;
  groupingsCommands: ViewpointGroupingRulesCommands;
  formattingCommands: ViewpointBuilderFormattingCommands;
  collapsePathsCommands: CollapsePathsCommands;
  requiredComponentsCommands: RequiredComponentsCommands;
  selectedViewCommands: SelectedViewCommands;
};

export type ViewpointBuilderContext =
  //  Edit a viewpoint from the viewpoint list.
  | 'editViewpoint'
  // Create new viewpoint from the homepage.
  | 'createViewpoint'
  // Open a viewpoint from the homepage.
  | 'openViewpoint'
  // Edit a subgraph block from the configuration panel.
  | 'editSubgraph'
  // Edit a search block from the configuration panel.
  | 'editSearch'
  // Open the viewpoint builder from context menu.
  | 'contextMenu'
  // Open the viewpoint builder to load a new viewpoint, e.g. 'Explore data' and 'Open more' buttons.
  | 'loadNewViewpoint';

export type OpenViewpointBuilderArgs = {
  activeTab?: ViewpointBuilderTab;
  context?: ViewpointBuilderContext;
  initialConfiguration?: InitialViewpointBuilderConfiguration;
  folderId?: string | null;
};

export type InitialViewpointBuilderConfiguration = Partial<
  Pick<
    APIViewpointAttributes,
    | 'name'
    | 'description'
    | 'viewName'
    | 'startType'
    | 'paths'
    | 'filters'
    | 'groupBys'
    | 'conditionalFormatting'
  >
> & {
  viewpointId?: string | null;
  viewpointVersion?: number | null;
  loadedStateHash?: string;
  initialPaths?: DirectedTripleWithFilters[][];
  startSearchTerm?: string;
  loadedComponents?: ComponentSearchData[];
  componentSelection: ComponentSelection | null; // null for creating and editing viewpoint
  pathMatching?: TraversalPathMatchingType;
  folderId?: string;
  pathCollapsingRules?: PathCollapsingRule[];
};

export type DisableableDirectedTripleWithFilters = DirectedTripleWithFilters & {
  isDisabled?: boolean;
  togglingDisabledExplanation?: string;
};

export type VirtualEdgeState = {
  collapsedEdges: string[];
  /**
   * Not all edges which are part of the collapsed path should be removed on
   * collapsing a path in the viewpoint builder. For more details check the
   * comments of the `updateEdgesToBeRemovedOnCollapsing` method.
   */
  edgesToBeRemovedOnCollapsing: string[];
  virtualEdge: GraphEdgeWithMetaData;
  tripleReplacements: TripleOptionReplacement[];
  /**
   * How many times a specific match for a collapsed path is matched in the
   * paths of the whole graph. In a example:
   *   Paths:
   *     A -> B -> C -> D -> E
   *     A -> B -> C -> D -> F
   *   Collapsing rule: B -> C -> D
   *   Match count: 2
   * This is used to determine if we can removed collapsed edges from the graph
   * when collapsing a path.
   */
  matchCount: number;
};

export type TripleOptionReplacement = {
  /**
   * To specify for which end of the collapsed path this triple is a replacement.
   */
  start: 'start' | 'end';
  /**
   * The first or the last node of the collapsed path depending on the `start`
   * value.
   */
  graphNodeId: GraphItemId;
  /**
   * The triple id of the collapsed start which this is a replacement for.
   */
  tripleId: string;
  /**
   * The direction of the triple which this is a replacement for.
   */
  direction: TripleDirection;
  replacementDirection: TripleDirection;
  tripleReplacement: TripleOption;
};

export type PathCollapsingRuleInternal = PathCollapsingRule & {
  isExpanded: boolean;
  virtualEdgeStates: VirtualEdgeState[];
};
