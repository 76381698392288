import {
  APIMetaModelPreview,
  ArdoqId,
  ComponentTypeRelatedFieldsAndTriples,
  PersistedUseCase,
  UseCaseSummary,
} from '@ardoq/api-types';
import { ArdoqError, Maybe, Result } from '@ardoq/common-helpers';
import { SimpleViewModel } from '../../viewpointBuilder/types';
import { IconName } from '@ardoq/icons';
import { DescriptionAndBusinessOutcomesProps } from './ExpandableDescriptionAndBusinessOutcomes';
import { SelectOptionWithContextData } from '../types';

export enum UseCaseLandingPageTab {
  METAMODEL = 'METAMODEL',
  SOLUTION_MATERIAL = 'SOLUTION_MATERIAL',
}

type ErrorProps = {
  error: ArdoqError;
};

export type UseCaseLandingPageState = {
  selectedUseCaseMetaModelPreview: Result<APIMetaModelPreview> | null;
  isLoadingUseCase: boolean;
  selectedUseCaseId: Maybe<string>;
  selectedUseCase: Maybe<UseCaseSummary>;
  useCases: UseCaseSummary[];
  activeTab: UseCaseLandingPageTab;
  useCasePreview: Maybe<PersistedUseCase>;
  loadUseCaseErrorProps: Maybe<ErrorProps>;
};

type LandingPageErrorProps = { error: Error };

export const isLandingPageErrorProps = (
  props: UseCaseLandingPageProps
): props is LandingPageErrorProps => 'error' in props;

export type UseCaseLandingPageProps =
  | LandingPageErrorProps
  | ({
      isLoadingUseCase: boolean;
      selectUseCase: (useCase: ArdoqId | null) => void;
      useCaseSelectOptions: Array<SelectOptionWithContextData>;
      loadUseCase: () => Promise<void>;
      loadUseCaseErrorProps: Maybe<ErrorProps>;
      isOrgAdmin: boolean;
    } & (
      | {
          selectedUseCase: UseCaseSummary;
          showAugmentations: boolean;
          augmentationEnablement: AugmentationEnablement;
          islandProps: UseCaseLandingPageIslandProps;
          descriptionAndBusinessOutcomesProps: DescriptionAndBusinessOutcomesProps;
        }
      | {
          selectedUseCase: null;
          showAugmentations: null;
          augmentationEnablement: null;
          islandProps: null;
          descriptionAndBusinessOutcomesProps: null;
        }
    ));

export type AugmentationEnablement = {
  valueProposition: {
    enabled: boolean;
    disabledReason?: string;
    completed: boolean;
  };
  valueStream: {
    enabled: boolean;
    disabledReason?: string;
    completed: boolean;
  };
  businessCapabilityMap: {
    enabled: boolean;
    disabledReason?: string;
    completed: boolean;
  };
  linking: {
    enabled: boolean;
    disabledReason?: string;
    completed: boolean;
  };
};

export type UseCaseTabsProps = {
  tabId: UseCaseLandingPageTab;
  label: string;
  isActive: boolean;
  onClick: VoidFunction;
};

export type UseCaseLandingPageMetamodelPreviewProps = {
  useCaseName: string;
  metamodelPreviewContentProps:
    | MetamodelPreviewContentProps
    | MetamodelPreviewErrorProps;
};
type MetamodelPreviewContentProps = {
  viewModel: SimpleViewModel;
  componentTypeFieldsAndTriples: ComponentTypeRelatedFieldsAndTriples[];
};

export type MetamodelPreviewErrorProps = {
  error: ArdoqError;
};

export const isMetamodelPreviewErrorProps = (
  props: MetamodelPreviewContentProps | MetamodelPreviewErrorProps
): props is MetamodelPreviewErrorProps => 'error' in props;

export type UseCasePreviewContent = {
  count: number;
  names: Array<{
    _id: ArdoqId;
    name: string;
    onMaterialClick?: VoidFunction;
  }>;
  label: string;
  iconName?: IconName;
};

export type UseCasePreviewContentProps = {
  content: UseCasePreviewContent[];
};

export type UseCaseLandingPageIslandProps = {
  tabProps: UseCaseTabsProps[];
} & (
  | {
      activeTab: UseCaseLandingPageTab.METAMODEL;
      metamodelPreviewProps: UseCaseLandingPageMetamodelPreviewProps;
    }
  | {
      activeTab: UseCaseLandingPageTab.SOLUTION_MATERIAL;
      previewContentProps: UseCasePreviewContentProps;
    }
  | {
      activeTab: null;
    }
);

export type MetaModelSidebarProps = {
  metaModelData: ComponentTypeRelatedFieldsAndTriples[];
};

export type AugmentationsProps = {
  enabledState: AugmentationEnablement;
};
