import { SecondaryButton, PrimaryButton } from '@ardoq/button';
import { Box, FlexBox } from '@ardoq/layout';
import { StatusType, SuccessNotification } from '@ardoq/status-ui';
import { TabsGroup, TabButton } from '@ardoq/tabs';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { VisualComponentsMappings } from './VisualComponentsMappings';
import { VisualReferencesMappings } from './VisualReferencesMappings';
import { VisualMapperProps } from './types';
import styled from 'styled-components';

const MappingContainer = styled(Box)`
  max-height: 70vh;
  min-height: 400px;
  overflow-y: auto;
`;

export const VisualMappings = ({
  mappingsStatus,
  mappingsComponents,
  mappingsReferences,
  mappingsErrors,
  componentTypeOptions,
  referenceTypeOptions,
  componentsOptions,
  onAnalyze,
  onImport,
  onComponentMapping,
  onComponentTypeChange,
  onAddNewComponentMapping,
  onDeleteComponentMapping,
  onReferenceMapping,
  onAddNewReferenceMapping,
  onDeleteReferenceMapping,
}: VisualMapperProps) => {
  const componentTabProps =
    mappingsErrors.hasErrors && mappingsErrors.components.length
      ? {
          tagStatusType: StatusType.WARNING,
          tag: `${mappingsErrors.components.length}`,
        }
      : {};

  const referenceTabProps =
    mappingsErrors.hasErrors && mappingsErrors.references.length
      ? {
          tagStatusType: StatusType.WARNING,
          tag: `${mappingsErrors.references.length}`,
        }
      : {};

  return (
    <FlexBox flex={1} width="full" height="full">
      <FlexBox flexDirection={'column'} gap="medium" width="full">
        <MappingContainer flexGrow={1}>
          <TabsGroup defaultActiveTabId="components">
            <TabButton
              label="Components"
              tabId="components"
              {...componentTabProps}
            >
              <VisualComponentsMappings
                mappingsStatus={mappingsStatus}
                componentTypeOptions={componentTypeOptions}
                components={mappingsComponents}
                onComponentMapping={onComponentMapping}
                onComponentTypeChange={onComponentTypeChange}
                onAddNewComponentMapping={onAddNewComponentMapping}
                onDeleteComponentMapping={onDeleteComponentMapping}
              />
            </TabButton>
            <TabButton
              label="References"
              tabId="references"
              {...referenceTabProps}
            >
              <VisualReferencesMappings
                mappingsStatus={mappingsStatus}
                references={mappingsReferences}
                componentsOptions={componentsOptions}
                referenceTypeOptions={referenceTypeOptions}
                onReferenceMapping={onReferenceMapping}
                onAddNewReferenceMapping={onAddNewReferenceMapping}
                onDeleteReferenceMapping={onDeleteReferenceMapping}
              />
            </TabButton>
          </TabsGroup>
        </MappingContainer>

        {mappingsStatus === ASYNC_STATUS.SUCCESS && (
          <SuccessNotification>Imported data successfully.</SuccessNotification>
        )}

        <FlexBox gap="medium" justify="end" paddingBottom="xlarge">
          <SecondaryButton onClick={onAnalyze}>Analyze again</SecondaryButton>

          {mappingsStatus !== ASYNC_STATUS.SUCCESS && (
            <PrimaryButton
              isDisabled={mappingsErrors.hasErrors}
              onClick={onImport}
            >
              Import to Ardoq
            </PrimaryButton>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
