import {
  augmentationModal$,
  AugmentationModalState,
} from './augmentationModal$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import {
  setCompany,
  setDescription,
  setIndustry,
  setValueProposition,
  generateBusinessCapabilityMap,
  submitBusinessCapabilityMapModal,
} from './actions';
import AugmentationModal from './AugmentationModal';

type BusinessCapabilityMapModalViewProps = {
  company: string;
  setCompany: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  industry: string;
  setIndustry: (value: string) => void;
  valueProposition: string;
  setValueProposition: (value: string) => void;
  loading: boolean;
  handleSubmit: (closeModal: () => void) => void;
  handleGenerate: () => void;
  closeModal: () => void;
  disableSubmit: boolean;
  disableGenerate: boolean;
  isGenerationError: boolean;
  isGenerationSuccess: boolean;
};

const BusinessCapabilityMapModalView = (
  props: BusinessCapabilityMapModalViewProps
) => {
  return (
    <AugmentationModal
      simpleValueProposition
      {...props}
      modalDescription="The Business Capability Map  will be generated based on placeholder and a scenario will be created..."
      submitText="Open Scenario"
      generateName="Business Capability Map"
    />
  );
};

const toProps = (
  state: AugmentationModalState
): Omit<BusinessCapabilityMapModalViewProps, 'closeModal'> => ({
  company: state.company,
  setCompany: company => {
    dispatchAction(setCompany(company));
  },
  description: state.description,
  setDescription: description => {
    dispatchAction(setDescription(description));
  },
  industry: state.industry,
  setIndustry: industry => {
    dispatchAction(setIndustry(industry));
  },
  valueProposition: state.valueProposition,
  setValueProposition: vp => {
    dispatchAction(setValueProposition(vp));
  },
  loading: state.loading,
  disableSubmit: state.disableSubmit,
  disableGenerate: state.disableGenerate,
  handleSubmit: closeModal => {
    dispatchAction(
      submitBusinessCapabilityMapModal({
        closeModal,
        streamLink: state.generationLink,
      })
    );
  },
  handleGenerate: () => {
    dispatchAction(
      generateBusinessCapabilityMap({
        company: state.company,
        description: state.description,
        valueProposition: state.valueProposition,
        industry: state.industry,
      })
    );
  },
  isGenerationSuccess: Boolean(state.generationLink),
  isGenerationError: state.generationError,
});

export default connect(
  BusinessCapabilityMapModalView,
  augmentationModal$.pipe(map(toProps))
);
