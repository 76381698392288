import {
  AppMainSidebarBotButtonStreamProps,
  AppMainSidebarTopButtonStreamProps,
  AppMainSidebarProps,
} from './types';
import { Features, hasFeature } from '@ardoq/features';
import { map } from 'rxjs/operators';
import { AppModules } from 'appContainer/types';
import selectedModule$ from 'appContainer/selectedModule$';
import metamodelNavigation$ from 'metamodel/navigation/metamodelNavigation$';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import { isOnPremise } from 'appConfig';
import { context$ } from 'streams/context/context$';
import type { ContextShape } from '@ardoq/data-model';
import { derivedStream } from '@ardoq/rxbeach';
import { MetamodelNavigationState } from 'metamodel/navigation/types';
import { currentScenario$ } from 'currentScenario$';
import broadcast$ from 'broadcasts/broadcast$';
import { CurrentBroadcast } from 'broadcasts/types';
import reportNavigation$ from '../../report/navigation/reportNavigation$';
import {
  APIDiscoverViewpointAttributes,
  ArdoqId,
  UseCaseSummary,
  APIScenarioAttributes,
  APIReportAttributes,
  APIModelAttributes,
  OrgAccessLevel,
} from '@ardoq/api-types';
import dashboards$ from '../../streams/dashboards/dashboards$';
import { Observable } from 'rxjs';
import dashboardNavigation$ from 'dashboard/navigation/dashboardNavigation$';
import { componentInterface } from '@ardoq/component-interface';
import viewpoints$ from 'viewpoints/viewpoints$';
import { appMainSidebarCommands } from './commands';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import { surveyInterface } from 'modelInterface/surveys/surveyInterface';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import activeView$, {
  ActiveViewState,
} from 'streams/views/mainContent/activeView$';
import { AvailableViews, availableViews$ } from 'views/availableViews$';
import useCases$ from 'useCases/useCases$';
import { DashboardsStreamShape } from 'streams/dashboards/types';
import { SurveyAdminStreamState } from 'surveyAdmin/streams/types';
import { IconName } from '@ardoq/icons';
import { MenuItemProps } from 'components/AppMainSidebarMenu/types';
import {
  createFromSelectedComponent,
  editTemplates,
  goToReportAndDashboardOverview,
  logout,
  openActiveMetamodel,
  openAdvancedSearch,
  openArdoqApi,
  openAssetsBrowserDialog,
  openChatWithUs,
  openCopyWorkspacesModal,
  openDashboard,
  openEmailPreferencies,
  openGraphFilters,
  openGremlinSearch,
  openHelp,
  openHome,
  openIntegrationPage,
  openManageUsers,
  openMetamodelOverview,
  openOrganizationMetamodelOverview,
  openOrganizationMetamodelTriples,
  openPresentations,
  openProductPortal,
  openReportInReader,
  openReportOverview,
  openUserForum,
  openWorkspace,
  reportBug,
} from './utils';
import openAboutBox from './openAboutBox';
import { AppMainSidebarCommands } from './types';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToUserSettings } from 'admin/user/actions';
import { UserSettingsTabs } from 'admin/user/types';
import { openGlobalFieldManager } from 'fields/globalFieldManager/actions';
import {
  isDisabled,
  Permissions,
} from 'streams/currentUserPermissions/permissionInterface';
import {
  initiateNavigationToActiveBroadcastForm,
  initiateNavigationToNewBroadcastForm,
  navigateToAccessControlPage,
  navigateToAuditLog,
  navigateToBroadcastOverview,
  navigateToDashboardModule,
  navigateToDefaultViewpoints,
  navigateToManageOrganization,
  navigateToViewpointForm,
  navigateToViewpoints,
} from 'router/navigationActions';
import { triggerSalesDemoStep } from 'salesDemoTourLauncher/actions';
import {
  SALES_DEMO_TOUR_STEPS,
  TOUR_STEP_DETAILS,
} from 'salesDemoTourLauncher/consts';
import currentUserModel from 'models/currentUser';
import { APIDashboardAttributes, PersonalSetting } from '@ardoq/api-types';
import { OrgTabs } from 'admin/manageOrganization/navigation/types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { DashboardModule } from 'dashboard/types';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { viewpointAccessControlInterface } from 'resourcePermissions/accessControlHelpers/viewpoints';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { AuditLogTracking, trackAuditLogEntryPoint } from 'auditLog/tracking';
import { navigateToSurveyForm } from 'surveyAdmin/navigation/actions';
import { openSurveyAdminView } from 'surveyAdmin/navigation/utils';
import { canDo } from '@ardoq/access-control';
import { SurveyAdminMode } from 'surveyAdmin/navigation/types';
import { navigateToUseCases } from '../../useCases/actions';
import { openChatWindow } from 'bestPracticeAssistant/actions';
import { useCaseOperations } from 'useCases/useCaseOperations';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import reports$ from 'streams/reports/reports$';
import { trackEvent } from 'tracking/tracking';
import { CollectionStream, StreamCollection } from 'streams/utils/streamUtils';
import { models$ } from 'streams/models/models$';
import { inventoryTracking } from 'inventory/inventoryTracking';

type State = [
  appModules: { selectedModule: AppModules },
  context: ContextShape,
  metamodelNavigationState: MetamodelNavigationState,
  surveyAdminState: SurveyAdminStreamState,
  scenario: APIScenarioAttributes | null,
  broadcast: { currentBroadcast: CurrentBroadcast | null },
  report: { reportId?: ArdoqId },
  reports: CollectionStream<APIReportAttributes>,
  selectedDashboard: { selectedDashboardId: ArdoqId | null },
  dashboards: DashboardsStreamShape,
  viewpointsState: { viewpoints: APIDiscoverViewpointAttributes[] },
  permissionsContext: PermissionContext,
  activeView: ActiveViewState,
  availableViews: AvailableViews,
  useCases: UseCaseSummary[],
  models: StreamCollection<APIModelAttributes>,
];

const mapStateToProps = ([
  { selectedModule },
  context,
  { metamodel },
  { surveyId },
  scenario,
  { currentBroadcast },
  { reportId },
  { byId: reportsById },
  { selectedDashboardId },
  { dashboardsById },
  { viewpoints },
  permissionsContext,
  { mainViewId },
  { byIds },
  useCases,
  { models },
]: State): AppMainSidebarProps => {
  // Prevent to open workspace util scenario is closed
  const openedWorkspacesCount = scenario ? 0 : context.workspacesIds.length;

  // WORKSPACES MENU FLAGS
  // there are some cases when context is not updated as it should - seems that context$ is buggy (?)
  const hasWriteAccess = Boolean(
    permissionsContext.user &&
      permissionsOperations.isOrgWriter(permissionsContext.user)
  );
  const isOrgAdmin = Boolean(
    permissionsContext.user &&
      permissionsOperations.isOrgAdmin(permissionsContext.user)
  );

  const canCopyWsToOrg =
    isOrgAdmin && hasFeature(Features.COPY_WORKSPACES_BETWEEN_ORGS);

  // ANALYTICS MENU FLAGS
  const canCreateGraphFilter =
    graphSearchAccessControlHelpers.canCreateGraphFilter(permissionsContext);
  const hasTraversals = hasFeature(Features.SUPPORT_LARGE_DATASETS);

  // META MODEL MENU FLAGS
  const hasMetamodelFeature = isOrgAdmin;
  const activeMetamodelName = metamodel ? metamodel.name : null;

  // META MODEL EDITOR FLAGS
  const hasMetamodelEditorAlphaFeature = hasFeature(
    Features.METAMODEL_EDITOR_ALPHA
  );

  const canManageTriples = isOrgAdmin && hasMetamodelEditorAlphaFeature;

  // SURVEY MENU FLAGS
  const selectedComponentName =
    componentInterface.getDisplayName(context.componentId) ?? '';
  const surveyModel = surveyInterface.getSurveyAttributes(surveyId);
  const activeSurveyName = surveyModel ? surveyModel.name : null;
  const activeSurveyId = surveyModel ? surveyModel._id : null;

  // HELP MENU FLAGS
  const isTrial = Boolean(permissionsContext.user?.organization?.['is-trial']);
  const isOnPrem = isOnPremise();
  const hasUserForum = !(isTrial || isOnPrem);

  // BROADCASTS MENU FLAGS
  const hasBroadcastsFeature = hasFeature(Features.BROADCASTS);
  const hasActiveBroadcast = Boolean(currentBroadcast);
  const activeBroadcastName = currentBroadcast?.name ?? null;

  // SALES DEMO TOUR MENU FLAGS
  const hasSalesDemoTourFeature = hasFeature(Features.SALES_DEMO_TOUR_LAUNCHER);

  // HAS DISABLED CREATE TEMPLATE FROM WORKSAPCE AND NO TEMPLATES
  const hasDisabledCreateTemplateFromWorkspaceMetamodel = hasFeature(
    Features.DISABLE_CREATE_TEMPLATE_FROM_WORKSPACE_METAMODEL
  );
  const hasNoCustomTemplates =
    models.filter(model => {
      const isArdoqCommon =
        permissionsContext.user?.organization?.label === 'ardoq-common';

      const isCustomTemplate = !model.common && model.useAsTemplate;

      return isArdoqCommon || isCustomTemplate;
    }).length === 0;
  const hideCustomTemplates =
    hasDisabledCreateTemplateFromWorkspaceMetamodel && hasNoCustomTemplates;

  const activeViewName = byIds[mainViewId]?.name ?? null;

  const state = {
    selectedModule,
    hasWriteAccess,
    canCopyWsToOrg,
    hasChat: !isOnPrem,
    hasUserForum,
    selectedComponentName,
    isAdmin: isOrgAdmin,
    isTrial,
    hasMetamodelFeature,
    hasMetamodelEditorAlphaFeature,
    activeMetamodelName,
    openedWorkspacesCount,
    activeSurveyName,
    activeSurveyId,
    hasBroadcastsFeature,
    hasActiveBroadcast,
    activeBroadcastName,
    hasSalesDemoTourFeature,
    activeReportId: reportId && reportsById[reportId] ? reportId : undefined,
    activeDashboard:
      (selectedDashboardId && dashboardsById[selectedDashboardId]) || null,
    viewpoints: viewpoints || [],
    hasTraversals,
    commands: appMainSidebarCommands,
    canCreateGraphFilter,
    canManageTriples,
    permissionsContext,
    activeViewName,
    useCases,
    hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
    hideCustomTemplates,
    hasShiftXFeature: hasFeature(Features.PROCESS),
  };

  return {
    ...state,
    topSectionButtons: getTopButtons(state),
    bottomSectionButtons: getBottomButtons(state),
  };
};

const appMainSidebar$ = (
  derivedStream(
    'appMainSidebar$',
    selectedModule$,
    context$,
    metamodelNavigation$,
    surveyAdmin$,
    currentScenario$,
    broadcast$,
    reportNavigation$,
    reports$,
    dashboardNavigation$,
    dashboards$,
    viewpoints$,
    currentUserPermissionContext$,
    activeView$,
    availableViews$,
    useCases$,
    models$
    // Hack because derivedStream accepts an arbitrary number of stream
    // arguments, but only has hard-coded typing for the first 10 (see
    // https://github.com/ardoq/rxbeach/issues/533)
  ) as Observable<State>
).pipe(map(mapStateToProps));

export default appMainSidebar$;

const getGoToLabel = (
  workspacesCount: number,
  activeViewName: string | null
): string => {
  const label =
    workspacesCount === 0
      ? '...'
      : activeViewName
        ? `${activeViewName} view`
        : 'open workspaces';
  return `Go to ${label}`;
};

type GetWorkspaceMenuOptions = {
  hasWriteAccess: boolean;
  canCopyWsToOrg: boolean;
  openedWorkspacesCount: number;
  activeViewName: string | null;
  hasNewJourneyFeature: boolean;
  isInWorkspaceAppModule: boolean;
  hideCustomTemplates: boolean;
};

const getHomeMenu = ({
  hasWriteAccess,
  canCopyWsToOrg,
  openedWorkspacesCount,
  activeViewName,
  hasNewJourneyFeature,
  isInWorkspaceAppModule,
  hideCustomTemplates,
}: GetWorkspaceMenuOptions): MenuItemProps[] => [
  {
    title: 'Home',
    trackingLabel: 'home',
    onClick: openHome,
  },
  {
    name: hasNewJourneyFeature
      ? 'Go to opened data'
      : getGoToLabel(openedWorkspacesCount, activeViewName),
    trackingLabel: 'go-to-open-workspaces',
    disabled: !openedWorkspacesCount,
    onClick: !isInWorkspaceAppModule ? openWorkspace : undefined,
  },
  {
    name: 'Open workspace',
    trackingLabel: 'open-workspace',
    onClick: openAssetsBrowserDialog,
  },
  {
    name: 'Import and integrations',
    trackingLabel: 'import-and-integrations',
    onClick: openIntegrationPage,
    hidden: !hasWriteAccess,
  },
  {
    name: 'Edit workspace templates',
    trackingLabel: 'edit-workspace-templates',
    onClick: editTemplates,
    hidden: !hasWriteAccess || hideCustomTemplates,
  },
  {
    name: 'Copy to organization',
    trackingLabel: 'copy-to-organization',
    onClick: openCopyWorkspacesModal,
    hidden: !canCopyWsToOrg,
  },
];

type GetAnalyticsMenuOptions = {
  activeReportId?: ArdoqId;
  activeDashboard: APIDashboardAttributes | null;
  canCreateGraphFilter: boolean;
  hasNewJourneyFeature: boolean;
};

const getAnalyticsMenu = ({
  canCreateGraphFilter,
  activeReportId,
  activeDashboard,
  hasNewJourneyFeature,
}: GetAnalyticsMenuOptions): MenuItemProps[] => {
  return [
    {
      title: 'Analytics',
      trackingLabel: 'analytics',
      onClick: () => goToReportAndDashboardOverview(hasNewJourneyFeature),
    },
    {
      name: 'Dashboards Overview',
      trackingLabel: 'dashboards-overview',
      onClick: () =>
        openDashboard({ dashboardModule: DashboardModule.OVERVIEW }),
    },
    {
      name: `Go to ${
        activeDashboard ? `"${activeDashboard.name}"` : 'open dashboard'
      }`,
      trackingLabel: 'go-to-open-dashboard',
      onClick: () =>
        activeDashboard &&
        openDashboard({
          dashboardId: activeDashboard._id,
        }),
      disabled: !activeDashboard,
    },
    {
      name: 'Reports Overview',
      trackingLabel: 'reports-overview',
      onClick: () => openReportOverview(),
    },
    {
      name: `Go to ${
        activeReportId
          ? `"${reports$.state.byId[activeReportId]?.name}"`
          : 'open report'
      }`,
      trackingLabel: 'go-to-open-report',
      onClick: () =>
        activeReportId &&
        openReportInReader({
          reportId: activeReportId,
        }),
      disabled: !activeReportId,
    },
    {
      name: 'Advanced search',
      trackingLabel: 'advanced-search',
      onClick: () => openAdvancedSearch(),
    },
    {
      name: 'Gremlin graph search',
      trackingLabel: 'gremlin-graph-search',
      onClick: () => openGremlinSearch(),
      hidden: !canCreateGraphFilter,
    },
  ];
};

type SurveyMenuOptions = {
  selectedComponentName?: string;
  activeSurveyName?: string | null;
  activeSurveyId?: ArdoqId | null;
  canCreateSurvey: boolean;
  permissionsContext: PermissionContext;
  currentWorkspaceId: ArdoqId | null;
  hasNewJourneyFeature: boolean;
};
const getSurveyMenu = ({
  selectedComponentName,
  activeSurveyName,
  activeSurveyId,
  currentWorkspaceId,
  canCreateSurvey,
  permissionsContext,
  hasNewJourneyFeature,
}: SurveyMenuOptions): MenuItemProps[] => {
  if (hasNewJourneyFeature)
    return [
      {
        title: 'Surveys',
        trackingLabel: 'surveys',
        onClick: () => openSurveyAdminView(SurveyAdminMode.OVERVIEW),
      },
    ];
  return [
    {
      title: 'Surveys',
      trackingLabel: 'surveys',
      onClick: () => openSurveyAdminView(SurveyAdminMode.OVERVIEW),
    },
    {
      name: activeSurveyName
        ? `Go to "${activeSurveyName}"`
        : 'Go to open survey',
      trackingLabel: 'go-to-open-survey',
      onClick: () => {
        if (!activeSurveyId) return;
        dispatchAction(navigateToSurveyForm(activeSurveyId));
      },
      disabled: !activeSurveyName,
      hidden: !canCreateSurvey,
    },
    {
      name: selectedComponentName
        ? `Create from "${selectedComponentName}"`
        : 'Create from selected component',
      trackingLabel: 'create-from-selected-component',
      onClick: createFromSelectedComponent,
      disabled:
        !currentWorkspaceId ||
        !workspaceAccessControlInterface.canAdminWorkspace(
          permissionsContext,
          currentWorkspaceId,
          null
        ) ||
        !selectedComponentName ||
        isDisabled(Permissions.SURVEY_CREATE_FROM_COMPONENT),
      hidden: !canCreateSurvey,
    },
  ];
};

type HelpMenuOptions = {
  hasChat: boolean;
  hasUserForum: boolean;
  isTrial: boolean;
};
const getHelpMenu = ({
  hasChat,
  hasUserForum,
  isTrial,
}: HelpMenuOptions): MenuItemProps[] => [
  {
    title: 'Help & Support',
    trackingLabel: 'help-and-support',
    onClick: openAboutBox,
  },
  {
    name: 'Product portal',
    trackingLabel: 'product-portal',
    onClick: openProductPortal,
    hidden: isTrial,
  },
  {
    name: 'Knowledge base',
    trackingLabel: 'knowledge-base',
    onClick: openHelp,
  },
  {
    name: 'Ardoq community',
    trackingLabel: 'ardoq-community',
    onClick: openUserForum,
    hidden: !hasUserForum,
  },
  { name: 'Ardoq API', trackingLabel: 'ardoq-api', onClick: openArdoqApi },
  {
    name: 'Report bug',
    trackingLabel: 'report-bug',
    onClick: reportBug,
    hidden: !hasChat,
  },
  { name: 'About', trackingLabel: 'about', onClick: openAboutBox },
];

type PreferencesMenuOptions = {
  isAdmin: boolean;
  canCreateGraphFilter: boolean;
  canManageTriples: boolean;
  permissionContext: PermissionContext;
};
const getPreferencesMenu = ({
  isAdmin,
  canCreateGraphFilter,
  canManageTriples,
  permissionContext,
}: PreferencesMenuOptions): MenuItemProps[] => [
  {
    title: 'Preferences',
    trackingLabel: APPSEC_EVENTS.CLICKED_PREFERENCES,
    onClick: () =>
      dispatchAction(navigateToUserSettings(UserSettingsTabs.PROFILE)),
  },
  {
    name: 'Audit log',
    trackingLabel: AuditLogTracking.CLICKED_AUDIT_LOG_MENU_ITEM,
    onClick: () => {
      trackAuditLogEntryPoint('audit log');
      dispatchAction(navigateToAuditLog(null));
    },
    isNewFeature: !currentUserModel.getPersonalSetting(
      PersonalSetting.HAS_SEEN_AUDIT_LOG
    ),
  },
  {
    name: 'Usage metrics',
    trackingLabel: APPSEC_EVENTS.CLICKED_USAGE_METRICS_DASHBOARD,
    hidden: !currentUserInterface.currentUserIsOrgAdmin(),
    onClick: () =>
      dispatchAction(
        navigateToDashboardModule({
          dashboardModule: DashboardModule.USAGE_METRICS,
        })
      ),
    isNewFeature: !currentUserModel.getPersonalSetting(
      PersonalSetting.HAS_SEEN_USAGE_METRICS_DASHBOARD
    ),
  },
  {
    name: 'Your account settings',
    trackingLabel: APPSEC_EVENTS.CLICKED_YOUR_ACCOUNT_SETTINGS,
    onClick: () =>
      dispatchAction(navigateToUserSettings(UserSettingsTabs.PROFILE)),
  },
  {
    name: 'Email preferences',
    trackingLabel: APPSEC_EVENTS.CLICKED_EMAIL_PREFERENCES,
    onClick: openEmailPreferencies,
  },
  {
    name: 'Organization settings',
    trackingLabel: APPSEC_EVENTS.CLICKED_ORGANIZATION_SETTINGS,
    onClick: () => {
      if (isAdmin) {
        dispatchAction(
          navigateToManageOrganization({
            manageOrgTab: OrgTabs.SETTINGS,
          })
        );
      } else {
        openManageUsers();
      }
    },
    submenu: [
      {
        name: 'Settings',
        trackingLabel: APPSEC_EVENTS.CLICKED_MANAGE_ORGANIZATION,
        onClick: () =>
          dispatchAction(
            navigateToManageOrganization({
              manageOrgTab: OrgTabs.SETTINGS,
            })
          ),
        hidden: !isAdmin,
      },
      {
        name: 'Features',
        isNewFeature: true,
        trackingLabel: APPSEC_EVENTS.CLICKED_MANAGE_FEATURE_SETTINGS,
        onClick: () =>
          dispatchAction(
            navigateToManageOrganization({
              manageOrgTab: OrgTabs.FEATURE_SETTINGS,
            })
          ),
        hidden: !isAdmin,
      },
      {
        name: 'Fields',
        trackingLabel: APPSEC_EVENTS.CLICKED_MANAGE_FIELDS,
        onClick: () => dispatchAction(openGlobalFieldManager()),
        hidden: !(
          permissionsOperations.isLoadedPermissionContext(permissionContext) &&
          canDo({
            action: 'organization/manage_global_fields',
            permissionContext,
            resourceId:
              permissionsOperations.getOrgIdFromContext(permissionContext),
          })
        ),
      },
      {
        name: 'Triples',
        trackingLabel: 'organization-metamodel-triples',
        onClick: openOrganizationMetamodelTriples,
        hidden: !canManageTriples,
      },
      {
        name: 'Migration checklist',
        trackingLabel: APPSEC_EVENTS.CLICKED_MIGRATION_CHECKLIST,
        onClick: () =>
          dispatchAction(
            navigateToManageOrganization({
              manageOrgTab: OrgTabs.MIGRATION_CHECKLIST,
            })
          ),
        hidden: !isAdmin,
      },
      {
        name: 'Graph filters',
        trackingLabel: APPSEC_EVENTS.CLICKED_GRAPH_FILTERS,
        onClick: openGraphFilters,
        hidden: !canCreateGraphFilter,
      },
      {
        name: 'Show admins',
        trackingLabel: APPSEC_EVENTS.CLICKED_SHOW_ADMINS,
        onClick: openManageUsers,
        hidden: isAdmin,
      },
    ],
  },
  {
    name: 'Access control',
    hidden: !isAdmin,
    dataClickId: 'access-control-sidebar-button',
    trackingLabel: APPSEC_EVENTS.CLICKED_ACCESS_CONTROL,
    onClick: () => {
      dispatchAction(
        navigateToAccessControlPage({
          accessControlTab: AccessControlTabs.MEMBERS,
        })
      );
    },
  },
  {
    name: 'Log out',
    trackingLabel: APPSEC_EVENTS.CLICKED_LOG_OUT,
    onClick: logout,
  },
];

type MetamodelMenuOptions = {
  activeMetamodelName: string | null;
  hasNewJourneyFeature: boolean;
};
const getMetamodelMenu = ({
  activeMetamodelName,
  hasNewJourneyFeature,
}: MetamodelMenuOptions): MenuItemProps[] => {
  if (hasNewJourneyFeature) {
    return [
      {
        title: 'Metamodels',
        trackingLabel: 'metamodel',
        onClick: openMetamodelOverview,
      },
    ];
  }
  return [
    {
      title: 'Metamodel',
      trackingLabel: 'metamodel',
      onClick: openMetamodelOverview,
    },
    {
      name: activeMetamodelName
        ? `Go to "${activeMetamodelName}"`
        : 'Go to open metamodel',
      trackingLabel: 'go-to-open-metamodel',
      onClick: openActiveMetamodel,
      disabled: !activeMetamodelName,
    },
  ];
};

type BroadcastMenuOptions = {
  hasActiveBroadcast: boolean;
  activeBroadcastName: string | null;
  hasNewJourneyFeature: boolean;
};
const getBroadcastsMenu = ({
  hasActiveBroadcast,
  activeBroadcastName,
  hasNewJourneyFeature,
}: BroadcastMenuOptions) => {
  if (hasNewJourneyFeature)
    return [
      {
        title: 'Broadcasts',
        trackingLabel: 'broadcast-overview',
        onClick: () => dispatchAction(navigateToBroadcastOverview()),
      },
    ];
  return [
    {
      title: 'Create New Broadcast',
      trackingLabel: 'create-new-broadcast',
      onClick: () => dispatchAction(initiateNavigationToNewBroadcastForm(null)),
      dataClickId: 'create-new-broadcast-sidebar-button',
    },
    {
      name: 'Broadcast Overview',
      trackingLabel: 'broadcast-overview',
      onClick: () => dispatchAction(navigateToBroadcastOverview()),
    },
    {
      name: activeBroadcastName
        ? `Go to "${activeBroadcastName}"`
        : 'Go to open broadcast',
      trackingLabel: 'go-to-open-broadcast',
      onClick: () => dispatchAction(initiateNavigationToActiveBroadcastForm()),
      disabled: !hasActiveBroadcast,
    },
  ];
};

const getViewpointMenu = (
  isAdmin: boolean,
  hasNewJourneyFeature: boolean,
  discoverViewpointOverviewHidden?: boolean
) => {
  if (hasNewJourneyFeature)
    return [
      {
        title: 'Viewpoints',
        trackingLabel: 'go-to-viewpoint-overview',
        onClick: () => dispatchAction(navigateToViewpoints()),
      },
    ];
  return [
    {
      title: 'Go to viewpoint overview',
      trackingLabel: 'go-to-viewpoint-overview',
      onClick: () => dispatchAction(navigateToViewpoints()),
      hidden: discoverViewpointOverviewHidden,
    },
    {
      name: 'Create New Viewpoint',
      trackingLabel: 'create-new-viewpoint',
      onClick: () => dispatchAction(navigateToViewpointForm(null)),
      hidden: !viewpointAccessControlInterface.canCreateViewpoint(),
    },
    {
      name: 'Set default viewpoints',
      trackingLabel: 'set-default-viewpoints',
      onClick: () => dispatchAction(navigateToDefaultViewpoints()),
      hidden: !isAdmin,
    },
    {
      name: 'Go to Discover',
      trackingLabel: 'go-to-discover',
      onClick: () => window.open(`/discover/`, '_blank'),
    },
    {
      name: 'Configure Discover',
      trackingLabel: 'configure-discover',
      onClick: () => window.open(`/discover/?configure`, '_blank'),
      hidden: !isAdmin,
    },
  ];
};

const getSalesDemoTourMenu = () =>
  SALES_DEMO_TOUR_STEPS.map(salesDemoStep => ({
    title: TOUR_STEP_DETAILS[salesDemoStep].menuLabel,
    trackingLabel: TOUR_STEP_DETAILS[salesDemoStep].trackingLabel,
    onClick: () => dispatchAction(triggerSalesDemoStep({ salesDemoStep })),
  }));

type TraversalMenuProps = Pick<
  AppMainSidebarCommands,
  'navigateToViewpointsOverview' | 'openViewpointBuilder'
>;

const getTraversalMenu = (props: TraversalMenuProps): MenuItemProps[] => [
  {
    name: 'Viewpoint overview',
    trackingLabel: 'go-to-traversals-overview',
    onClick: props.navigateToViewpointsOverview,
    isNewFeature: true,
  },
  {
    name: 'Open viewpoint builder',
    trackingLabel: 'open-viewpoint-builder',
    onClick: props.openViewpointBuilder,
    isNewFeature: true,
  },
];

const getTopButtons = ({
  hasWriteAccess,
  canCopyWsToOrg,
  selectedComponentName,
  selectedModule,
  isAdmin,
  openedWorkspacesCount,
  hasMetamodelFeature,
  hasMetamodelEditorAlphaFeature,
  activeMetamodelName,
  activeSurveyName,
  activeSurveyId,
  hasBroadcastsFeature,
  hasActiveBroadcast,
  activeBroadcastName,
  hasSalesDemoTourFeature,
  hasNewJourneyFeature,
  activeReportId,
  activeDashboard,
  viewpoints,
  hasTraversals,
  commands,
  canCreateGraphFilter,
  permissionsContext,
  activeViewName,
  useCases,
  hideCustomTemplates,
  hasShiftXFeature,
}: AppMainSidebarBotButtonStreamProps) => {
  const useCaseIdsWithUpdate = useCases
    .filter(useCaseOperations.hasUpdateAvailable)
    .map(({ _id }) => _id);

  const seenUseCasesByUser =
    currentUserModel.getPersonalSetting(
      PersonalSetting.USE_CASE_UPDATES_SEEN
    ) ?? [];
  const hasSeenUseCaseUpdates = useCaseIdsWithUpdate.every((uc: string) =>
    seenUseCasesByUser.includes(uc)
  );

  const discoverViewpointOverviewHidden =
    viewpointAccessControlInterface.getDiscoverViewpointOverViewHidden(
      viewpoints
    );

  const isInWorkspaceAppModule = selectedModule === AppModules.WORKSPACES;

  return [
    {
      isActive: selectedModule === AppModules.TRAVERSALS,
      iconName: IconName.ROCKET,
      menuItems: getTraversalMenu(commands),
      'data-click-id': 'app-main-sidebar-traversals-button',
      onClick: commands.navigateToViewpointsOverview,
      shouldShow: hasTraversals && !hasNewJourneyFeature,
    },
    {
      iconName: IconName.HOME,
      isActive:
        selectedModule &&
        [AppModules.HOME, AppModules.WORKSPACES].includes(selectedModule),
      'data-click-id': 'app-main-sidebar-workspace-button',
      onClick: openHome,
      menuItems: getHomeMenu({
        canCopyWsToOrg,
        hasWriteAccess,
        openedWorkspacesCount,
        activeViewName,
        hasNewJourneyFeature,
        isInWorkspaceAppModule,
        hideCustomTemplates,
      }),
    },
    {
      iconName: IconName.INVENTORY,
      isActive: selectedModule === AppModules.INVENTORY,
      onClick: () => {
        trackEvent('Sidebar: open inventory');
        inventoryTracking.trackOpenInventoryFromSidebar();
        commands.navigateToInventory();
      },
      'data-click-id': 'app-main-sidebar-inventory-button',
      shouldShow: hasFeature(Features.INVENTORY),
      menuItems: [
        {
          title: 'Inventory',
          trackingLabel: 'inventory',
          onClick: () => {
            trackEvent('Sidebar: open inventory');
            inventoryTracking.trackOpenInventoryFromSidebar();
            commands.navigateToInventory();
          },
        },
      ],
    },
    {
      isActive:
        selectedModule === AppModules.DASHBOARDS ||
        selectedModule === AppModules.SEARCH ||
        selectedModule === AppModules.REPORTS ||
        selectedModule === AppModules.ANALYTICS,
      iconName: IconName.ANALYTICS,
      menuItems: getAnalyticsMenu({
        activeReportId,
        activeDashboard,
        canCreateGraphFilter,
        hasNewJourneyFeature,
      }),
      'data-test-id': 'app-main-sidebar-analytics-button',
      onClick: () => {
        trackEvent('Sidebar: open analytics');
        goToReportAndDashboardOverview(hasNewJourneyFeature);
      },
    },
    {
      isActive: selectedModule === AppModules.SURVEY_ADMIN,
      'data-click-id': 'app-main-sidebar-surveys-button',
      iconName: IconName.SURVEYS,
      menuItems: getSurveyMenu({
        selectedComponentName,
        activeSurveyName,
        activeSurveyId,
        canCreateSurvey: surveyAccessControlInterface.canCreateSurvey(),
        currentWorkspaceId: workspaceInterface.getCurrentWsId(),
        permissionsContext,
        hasNewJourneyFeature,
      }),
      onClick: () => {
        trackEvent('Sidebar: open surveys');
        openSurveyAdminView(SurveyAdminMode.OVERVIEW);
      },
    },
    {
      isActive: selectedModule === AppModules.BROADCASTS,
      iconName: IconName.BROADCAST,
      menuItems: getBroadcastsMenu({
        hasActiveBroadcast,
        activeBroadcastName,
        hasNewJourneyFeature,
      }),
      'data-test-id': 'app-main-sidebar-broadcasts-button',
      onClick: () => {
        trackEvent('Sidebar: open broadcasts');
        dispatchAction(navigateToBroadcastOverview());
      },
      shouldShow: hasBroadcastsFeature,
    },
    {
      isActive: selectedModule === AppModules.VIEWPOINTS,
      iconName: IconName.VIEWPOINT,
      menuItems: getViewpointMenu(
        isAdmin,
        hasNewJourneyFeature,
        discoverViewpointOverviewHidden
      ),
      'data-test-id': 'app-main-sidebar-viewpoints-button',
      onClick: () => {
        dispatchAction(navigateToViewpoints());
      },
      shouldShow: hasNewJourneyFeature || !discoverViewpointOverviewHidden,
    },
    {
      isActive: selectedModule === AppModules.METAMODEL,
      iconName: IconName.METAMODELS,
      menuItems: getMetamodelMenu({
        activeMetamodelName,
        hasNewJourneyFeature,
      }),
      'data-click-id': 'app-main-sidebar-metamodel-button',
      onClick: () => {
        trackEvent('Sidebar: open metamodels');
        openMetamodelOverview();
      },
      shouldShow: hasMetamodelFeature,
    },
    {
      isActive: selectedModule === AppModules.ORGANIZATION_METAMODEL,
      iconName: IconName.NETWORK_ZONE,
      menuItems: [
        {
          title: 'Organization metamodel',
          trackingLabel: 'organization-metamodel',
          onClick: openOrganizationMetamodelOverview,
        },
      ],
      'data-click-id': 'app-main-sidebar-organization-metamodel-button',
      onClick: openOrganizationMetamodelOverview,
      shouldShow: hasMetamodelEditorAlphaFeature && isAdmin,
    },
    {
      isActive: selectedModule === AppModules.PRESENTATIONS,
      iconName: IconName.PRESENTATION,
      menuItems: [
        {
          title: 'Presentations',
          trackingLabel: 'presentations',
          onClick: openPresentations,
        },
      ],
      'data-click-id': 'app-main-sidebar-presentations-button',
      onClick: openPresentations,
    },
    {
      isActive: selectedModule === AppModules.USE_CASES,
      iconName: IconName.COLLECTIONS_BOOKMARK,
      hasNewItems: !hasSeenUseCaseUpdates,
      menuItems: [
        {
          title: 'Ardoq Solutions',
          trackingLabel: 'best-practice-module',
          onClick: () => {
            dispatchAction(navigateToUseCases());
          },
        },
      ],
      'data-click-id': 'app-main-sidebar-best-practice-modules-button',
      onClick: () => {
        dispatchAction(navigateToUseCases());
      },
    },
    {
      shouldShow: hasShiftXFeature,
      iconName: IconName.SHIFTX,
      menuItems: [
        {
          title: 'Open ShiftX',
          trackingLabel: 'shift-x',
          onClick: () => {
            window.open('/process', '_blank');
          },
        },
      ],
      'data-click-id': 'app-main-sidebar-shift-x-button',
      onClick: () => {
        window.open('/process', '_blank');
      },
      isExternalLink: true,
    },
    {
      iconName: IconName.ANIMATION_PLAY,
      menuItems: getSalesDemoTourMenu(),
      shouldShow: hasSalesDemoTourFeature,
    },
  ];
};

const getBottomButtons = ({
  hasChat,
  hasUserForum,
  isAdmin,
  isTrial,
  canCreateGraphFilter,
  canManageTriples,
  permissionsContext,
}: AppMainSidebarTopButtonStreamProps) => {
  const showSsoMapping = currentUserModel.isOrgAdmin();
  const ssoMappingIsNew = !currentUserModel.getPersonalSetting(
    PersonalSetting.HAS_SEEN_SSO_ATTRIBUTE_MAPPING
  );
  const auditLogIsNew = !currentUserModel.getPersonalSetting(
    PersonalSetting.HAS_SEEN_AUDIT_LOG
  );

  return [
    {
      iconName: IconName.COMMENT,
      menuItems: [
        ...(hasChat
          ? [
              {
                title: 'Chat with us',
                trackingLabel: 'chat-with-us',
                onClick: openChatWithUs,
              },
            ]
          : []),
        ...(hasFeature(Features.AZURE_OPENAI_BEST_PRACTICE_ASSISTANT) &&
        permissionsContext.user &&
        permissionsOperations.hasRequiredOrgAccessLevel(
          OrgAccessLevel.READER,
          permissionsContext.user
        )
          ? [
              {
                name: 'Best practice assistant',
                trackingLabel: 'best-practice-assistant',
                onClick: () =>
                  dispatchAction(openChatWindow({ type: 'default' })),
              },
            ]
          : []),
      ],
      onClick: openChatWithUs,
      shouldShow:
        hasChat || hasFeature(Features.AZURE_OPENAI_BEST_PRACTICE_ASSISTANT),
    },
    {
      iconName: IconName.HELP,
      menuItems: getHelpMenu({
        hasChat,
        hasUserForum,
        isTrial,
      }),
      onClick: openAboutBox,
    },
    {
      iconName: IconName.PERSON,
      menuItems: getPreferencesMenu({
        isAdmin,
        canCreateGraphFilter,
        canManageTriples,
        permissionContext: permissionsContext,
      }),
      'data-test-id': 'preferences-menu-button',
      onClick: () =>
        dispatchAction(navigateToUserSettings(UserSettingsTabs.PROFILE)),
      hasNewItems: (showSsoMapping && ssoMappingIsNew) || auditLogIsNew,
    },
  ];
};
