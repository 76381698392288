import {
  CollapsibleGraphGroup,
  GraphNode,
  WORKSPACE_ICON_REPRESENTATION,
} from '@ardoq/graph';
import { componentInterface } from '@ardoq/component-interface';
import { RepresentationData } from '@ardoq/data-model';
import { GroupType } from '@ardoq/api-types';
import { canvasResolveImage } from 'tabview/canvasRendering/canvasResolvedImages';
import { componentTypeRepresentationData } from 'componentRepresentationData';
import type {
  GraphNodesToRelationshipsNodesReducerState,
  RelationshipsViewNode,
} from 'tabview/relationshipDiagrams/types';

const isComponent = (
  graphNode: GraphNode | CollapsibleGraphGroup
): graphNode is GraphNode => graphNode.isComponent();

const reduceNodes = <TNode extends RelationshipsViewNode<TNode, unknown>>(
  state: GraphNodesToRelationshipsNodesReducerState<TNode>,
  graphNode: GraphNode | CollapsibleGraphGroup
) => {
  const children = state.groupChildren.get(graphNode as CollapsibleGraphGroup);
  const { modelId } = graphNode;

  const representationData: RepresentationData | null = isComponent(graphNode)
    ? componentInterface.getRepresentationData(modelId)
    : graphNode.type === GroupType.WORKSPACE
      ? WORKSPACE_ICON_REPRESENTATION
      : graphNode.type === GroupType.COMPONENT
        ? componentTypeRepresentationData(children?.[0]?.modelId) // this is a bit ugly to use the first child to get the component type info, but we don't have the actual identifier of the workspace model at this point.
        : null;
  if (representationData) {
    canvasResolveImage(representationData);
  }

  const node = state.createNode(
    graphNode,
    state.parent,
    children,
    representationData
  );
  if (children) {
    children.reduce(reduceNodes<TNode>, {
      ...state,
      parent: node,
    });
    node.children = children.map(
      childGraphNode => state.map.get(childGraphNode.id)!
    );
  }
  state.map.set(graphNode.id, node);
  return state;
};

export default reduceNodes;
