import { DASHBOARD_MAX_WIDTH } from '@ardoq/dashboard';
import { BrandButton, Button, ButtonGroup, ButtonType } from '@ardoq/button';
import { AugmentationsProps } from '../types';
import { Island } from '@ardoq/page-layout';
import { Drawer, showDrawer } from '@ardoq/snowflakes';
import { DescriptionTitle } from '../ExpandableDescriptionAndBusinessOutcomes';
import { Badge, StatusType } from '@ardoq/status-ui';
import { Text } from '@ardoq/typography';
import { SparklesIcon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';
import ValuePropositionModal from './ValuePropositionModal';
import ValueStreamModal from './ValueStreamModal';
import BusinessCapabilityMapModal from './BusinessCapabilityMapModal';
import BCMLinkingModal from './BCMLinkingModal';

const drawerProps = {
  shouldCloseOnEscapeKey: true,
  shouldCloseOnBackdropClick: true,
};

const drawerRenderProps = {
  drawerSize: 'small',
  icon: <SparklesIcon color={colors.brand50} />,
  orientation: 'vertical',
  paddingX: 'none',
} as const;

const Augmentations = ({ enabledState }: AugmentationsProps) => {
  const openValuePropositionModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'generateValuePropositionDrawer',
      renderDrawer: () => (
        <Drawer {...drawerRenderProps} title="Generate Value Proposition">
          <ValuePropositionModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  const openValueStreamModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'generateValueStreamDrawer',
      renderDrawer: () => (
        <Drawer {...drawerRenderProps} title="Generate Value Streams">
          <ValueStreamModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  const openBusinessCapabilityMapModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'generateBusinessCapabilityMapDrawer',
      renderDrawer: () => (
        <Drawer {...drawerRenderProps} title="Generate Business Capability Map">
          <BusinessCapabilityMapModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  const openBCMLinkingModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'bcmLinkingDrawer',
      renderDrawer: () => (
        <Drawer
          {...drawerRenderProps}
          title="Link Business Capability Map & Value Stream"
        >
          <BCMLinkingModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  return (
    <Island
      maxWidth={`${DASHBOARD_MAX_WIDTH}px`}
      isScrollable={false}
      bodySpacing="xlarge"
    >
      <FlexBox gap="small">
        <DescriptionTitle>AI-powered Value Streams</DescriptionTitle>
        <Badge statusType={StatusType.NEW} label="New" />
      </FlexBox>
      <Text color={'textSubtle'}>
        Generate value propositions and streams and map them to your business
        capabilities.
      </Text>
      <ButtonGroup>
        <BrandButton
          isDisabled={!enabledState.valueProposition.enabled}
          data-tooltip-text={enabledState.valueProposition.disabledReason}
          onClick={openValuePropositionModal}
        >
          <SparklesIcon color={colors.white} />
          Generate Value Proposition
        </BrandButton>
        <Button
          isDisabled={!enabledState.valueStream.enabled}
          data-tooltip-text={enabledState.valueStream.disabledReason}
          buttonType={ButtonType.SECONDARY}
          onClick={openValueStreamModal}
        >
          <SparklesIcon color={colors.surfaceActionSecondary} />
          Generate Value Stream
        </Button>
        <Button
          isDisabled={!enabledState.businessCapabilityMap.enabled}
          data-tooltip-text={enabledState.businessCapabilityMap.disabledReason}
          buttonType={ButtonType.SECONDARY}
          onClick={openBusinessCapabilityMapModal}
        >
          <SparklesIcon color={colors.surfaceActionSecondary} />
          Generate Business Capability Map
        </Button>
        <Button
          isDisabled={!enabledState.linking.enabled}
          data-tooltip-text={enabledState.linking.disabledReason}
          buttonType={ButtonType.SECONDARY}
          onClick={openBCMLinkingModal}
        >
          <SparklesIcon color={colors.surfaceActionSecondary} />
          Link Business Capability Map & Value Stream
        </Button>
      </ButtonGroup>
    </Island>
  );
};

export default Augmentations;
