import { connect } from '@ardoq/rxbeach';
import { Multiselect } from '@ardoq/select';
import { MultiselectProps } from '@ardoq/select';
import { viewModel$ } from './viewModel';
import { Box } from '@ardoq/layout';

export type WorkspacesSelectorProps = {
  options: MultiselectProps<string>['options'];
  value: MultiselectProps<string>['value'];
  onChange: MultiselectProps<string>['onChange'];
};

export function WorkspacesSelectorComponent({
  options,
  value,
  onChange,
}: WorkspacesSelectorProps) {
  return (
    <Box padding={'medium'}>
      <Multiselect
        value={value}
        onChange={onChange}
        label="Workspaces"
        options={options}
        helperText="Select atleast one workspace applicable to your Lucidchart document content"
      />
    </Box>
  );
}

export const WorkspacesSelector = connect(
  WorkspacesSelectorComponent,
  viewModel$
);
