import { FlexBox } from '@ardoq/layout';
import { PageBody, PageTopbar, PageWrapper } from '@ardoq/page-layout';
import { DocumentSelector } from './DocumentSelector/DocumentSelector';
import { dispatchAction } from '@ardoq/rxbeach';
import { WorkspacesSelector } from './WorkspacesSelector/WorkspacesSelector';
import { VisualMapper } from './VisualMapper/VisualMapper';
import { Button } from '@ardoq/button';
import { restartImport } from './streams/configs/actions';

export const Lucidchart = () => {
  return (
    <PageWrapper>
      <PageTopbar
        primaryContent="Lucidchart visual importer"
        secondaryContent="Import and integrations"
        secondaryButton={
          <Button
            onClick={() => {
              dispatchAction(restartImport());
            }}
          >
            Restart
          </Button>
        }
      />
      <PageBody
        leftContent={
          <FlexBox
            flexDirection={'column'}
            height="full"
            backgroundColor="bgDefault"
            borderColor="borderSubtle00"
            maxWidth="min(700px, 40%)"
            width="full"
            minWidth="540px"
          >
            <WorkspacesSelector />
            <VisualMapper />
          </FlexBox>
        }
      >
        <DocumentSelector />
      </PageBody>
    </PageWrapper>
  );
};
